<template>
  <div>
    <div class="container" align="left">
      <div class="d-inline-block mt-5">
        <h4 class="mb-4">
          <strong>{{ $t('CAR_title') }}</strong>
        </h4>
        <p>{{ $t('CAR_desc1') }}</p>
        <p class="mt-5" v-html="$t('CAR_desc2')"></p>
        <h5 class="mt-5">
          <strong>{{ $t('CAR_subtitle') }}</strong>
        </h5>
        <P class="mb-5">{{ $t('CAR_sub_desc1') }}</P>
      </div>
      <div class="con-box mb-5">
        <address v-html="$t('CAR_button')"></address>
        <div v-html="$t('CAR_box_desc1')" v-show="isLang === 0"></div>
        <div>{{ $t('CAR_box_desc2') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Consultation',
  data() {
    return {
      isLang: 0,
    };
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
};
</script>

<style scoped>
p {
  word-break: keep-all;
}

a:hover {
  text-decoration: underline !important;
}

.con-box {
  padding: 22px 30px;
  box-sizing: border-box;
  background-color: #e5e5e5;
  color: #333333;
}

.con-box address {
  margin-top: 10px;
  display: inline-block;
  padding: 0 30px;
  height: 40px;
  background-color: #ba2323;
  border-radius: 20px;
  color: #fff;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
}
</style>

<i18n>
{
  "ko": {
    "CAR_title": "상담 및 제보",
    "CAR_desc1": "공정하고 투명한 기업경영활동을 통해 사회로부터 신뢰받고 존경받는 일류 수준의 기업을 만들겠습니다.",
    "CAR_desc2": "이곳은 임직원의 불공정한 업무수행(공정거래 관련 법규 위반행위) 및 부당한 요구, 금품, 접대 편의의 수수 회계관련 부당한 업무처리 등에 대하여 상담과 제보를 할 수 있는 곳입니다. 제보는 실명 제보를 원칙으로 하고, 익명 제보시에는 내용 확인 및 회신이 지연될 수 있으며, 사실에 근거하지 않는 비방, 개인 사생활 관련 사항 등은 처리되지 아니 합니다. <span style='color: #dd2727'> 제보자의 신분 및 제보 내용은 비밀이 보호 됩니다.</span>",
    "CAR_subtitle": "신분보장 상세안내",
    "CAR_sub_desc1": "회사와 제보를 처리하는 담당자는 제보자의 신원과 제보내용이 공개되지 않도록 최대한의 비밀 유지를 하고 있습니다. 그러나 뜻하지 않게 제보자의 신분이 노출 되었을 경우에는 제보자의 인사상 또는 신분상 불이익을 당하지 않도록 최대한의 조치를 취할 것입니다. 제보자는 제보로 인하여 신분이 노출되었거나, 노출 될 위험이 있다고 판단되면 즉시 경영지원팀장에게 보호를 요청하시기 바랍니다. 필요하다고 판단되는 경우, 회사는 본인의 희망을 적극적으로 고려하여 타 직무로 전환 배치하는 인사적 조치와 배려를 취할 수 있습니다. 제보자에게 정당한 제보를 이유로 부당한 행위를 한 조직이나, 개인에 대해서는 관련 규제에 따라 제재 조치를 취할 것입니다.",
    "CAR_button": "<a href='https://ethics.sk.co.kr' style='color: #ffffff' target='_blank'>제보하기</a>",
    "CAR_box_desc1": "TEL : 064-793-5014&nbsp;&nbsp;| E-Mail : pinxethics@skpinx.co.kr",
    "CAR_box_desc2": "63525 제주특별자치도 서귀포시 안덕면 산록남로 863 SK 핀크스 (주) ESG TF 윤리경영 담당자"
  },
  "en": {
    "CAR_title": "Consultation and Reporting",
    "CAR_desc1": "We will strive to build a first-rate company trusted and respected by society through fair and transparent corporate management activities.",
    "CAR_desc2": "This is a place where employees can seek consultation and make reports regarding unfair business practices (violations of fair trade regulations), unjust demands, bribery, hospitality favors, and improper handling of accounting-related matters. Reporting is encouraged with the principle of providing information with the real identity, and in cases of anonymous reporting, confirmation and response may be delayed. Reports containing unfounded accusations, personal privacy-related matters, and information lacking factual basis will not be processed. <span style='color: #dd2727'>The identity and content of the whistle-blower will be kept confidential.</span>",
    "CAR_subtitle": "Detailed Information on Identity Protection",
    "CAR_sub_desc1": "The company and the designated personnel handling reports are committed to maintaining the utmost confidentiality to ensure that the identity and contents of the whistle-blower are not disclosed. However, in the event of unintentional exposure of the whistle-blower's identity, measures will be promptly taken to prevent any adverse consequences to the whistle-blower's personal or professional standing. If the whistle-blower believes that their identity is at risk of exposure or has been exposed due to the report, they are urged to immediately request protection from the Management support team. In cases deemed necessary, the company may take personnel actions, such as reassignment to another position, with due consideration for the whistle-blower's preferences. Sanctions will be taken in accordance with relevant regulations against organizations or individuals who commit unfair acts against whistle-blowers due to legitimate reporting.",
    "CAR_button": "<a href='https://ethics.sk.co.kr' style='color: #ffffff' target='_blank'>Submitting a Report</a>",
    "CAR_box_desc2": "SK Pinx Co., Ltd. ESG TF Ethics Management Manager, 863 Sanroknam-ro, Andeok-myeon, Seogwipo-si, Jeju Special Self-Governing Province, Korea."
  },
  "ja": {
  }
}
</i18n>
